<template>
  <div>
    <div class="title">用户协议</div>
    <div class="content" v-html="contentText"></div>
  </div>
</template>
<script>
import { getUserAgreement } from "@/service/index";
export default {
  name: "UserAgreement",
  data() {
    return {
      contentText: "",
    };
  },
  methods: {
    getUserAgreement() {
      getUserAgreement().then((res) => {
        if (res.success) {
          this.contentText = res.result[0].answer;
        }
      });
    },
  },
  mounted() {
    this.getUserAgreement();
  },
};
</script>
<style lang="scss" scoped>
.title {
  margin-top: 54px;
  padding: 20px 0;
  text-align: center;
  font-size: 20px;
}
.content {
  padding: 0 10px;
  padding-bottom: 40px;
  ::v-deep p {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
}
</style>